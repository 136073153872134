import React, { useEffect } from "react";
import { Alert as RSAlert } from "reactstrap";

function Alert({ text, variant = "success", toogle }) {
  useEffect(() => {
    let timeOutId;
    if (text) {
      timeOutId = setTimeout(toogle, 5000);
    }
    return () => {
      timeOutId && clearTimeout(timeOutId);
    };
  }, [text, toogle]);

  return (
    <>
      {text && (
        <RSAlert
          color={variant}
          className="position-fixed fixed-right"
          style={{ zIndex: 9999, top: 56, right: 32 }}
          toggle={toogle}
        >
          {text}
        </RSAlert>
      )}
    </>
  );
}

export default Alert;
