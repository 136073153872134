import * as Yup from "yup";

export const ArbitrageBotConfigSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name is too Short!")
    .max(50, "Name is too Long!")
    .required("Name is required"),
  spread: Yup.number()
    .min(0.001, "Spread is too small!")
    .max(0.05, "Spread is too large!")
    .required("Required"),
  sourceMarket: Yup.string().required("Required"),
  targetMarket: Yup.string().required("Required"),
  pairList: Yup.array().of(
    Yup.object().shape({
      pair: Yup.string().required("Required"),
      amount: Yup.number().required("Required"),
      status: Yup.boolean().required("Required"),
    })
  ),
});
