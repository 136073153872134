import { useEffect, useState } from "react";
import Input from "components/Input";
import { useStoreActions } from "easy-peasy";
import { ButtonGroup, Button, FormGroup } from "reactstrap";
import Select from "react-select";

function PairForm({
  index,
  pairList,
  pair,
  amount,
  status,
  handleChange,
  setFieldValue,
  errors,
  showError,
  removePair,
}) {
  const setConfirm = useStoreActions((state) => state.setConfirm);
  const [value, setValue] = useState();
  const [options, setOptions] = useState();

  useEffect(() => {
    console.log("set options");
    setOptions(
      pairList?.map((pair) => ({
        label: pair.key,
        value: pair.key,
      }))
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    var index = options?.findIndex(function (item, i) {
      return item?.value === pair;
    });
    options?.[index] && setValue(options[index]);
  }, [pair, options]);

  return (
    <FormGroup className="border rounded p-3">
      <div className="mb-4">
        <div className="d-flex justify-content-between ">
          <Select
            id={`pairList[${index}].pair`}
            name="pair"
            className="basic-single flex-fill"
            classNamePrefix="select"
            value={value}
            isClearable
            isSearchable
            options={options}
            styles={{
              input: (provided, state) => {
                return { ...provided, height: 33 };
              },
            }}
            onChange={(selectedValue) => {
              setFieldValue(`pairList[${index}].pair`, selectedValue?.value);
            }}
          />
          <div>
            <ButtonGroup>
              <Button
                className="ml-2"
                color={status ? "primary" : "secondary"}
                onClick={() => setFieldValue(`pairList[${index}].status`, true)}
              >
                Active
              </Button>
              <Button
                color={status ? "secondary" : "primary"}
                onClick={() =>
                  setFieldValue(`pairList[${index}].status`, false)
                }
              >
                Passive
              </Button>
            </ButtonGroup>
            <Button
              outline
              color="danger"
              className="ml-2"
              onClick={() =>
                setConfirm({
                  confirmMessage: `Are you sure delete the pair?`,
                  handleSubmit: () => removePair(index),
                })
              }
            >
              <i className="fas fa-trash-alt" />
            </Button>
          </div>
        </div>
        {errors?.pair && showError && (
          <p className="text-danger">
            <small>{errors?.pair}</small>
          </p>
        )}
      </div>

      <Input
        id={`pairList[${index}].amount`}
        label="Amount"
        value={amount}
        onChange={handleChange}
        type="number"
        error={{ errorMessage: errors?.amount, showError }}
      />
    </FormGroup>
  );
}

export default PairForm;
