import { FormGroup, Label, Input as ReactStrapInput } from "reactstrap";

function Input({
  id,
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  options,
  rightContent,
  error,
  inline,
  className,
  inputContainerstyle,
  containerStyle,
  inputStyle,
  inputref,
  ...rest
}) {
  const isCheck = type === "checkbox";

  return (
    <FormGroup inline={inline} check={isCheck} style={containerStyle}>
      {label && (
        <Label
          for={id}
          style={{
            ...(isCheck && {
              order: 1,
              marginBottom: "0.1rem",
              width: "max-content",
            }),
          }}
        >
          {label}
        </Label>
      )}
      <div className="d-flex" style={inputContainerstyle}>
        <ReactStrapInput
          innerRef={inputref}
          id={id}
          name={id}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={onChange}
          className={`${value ? "text-dark" : "text-light"} ${className || ""}`}
          style={inputStyle}
          {...rest}
        >
          {options && (
            <>
              <option className="text-dark" value="">
                Select a item
              </option>
              {options?.map(({ label, value }, index) => (
                <option className="text-dark" key={index} value={value}>
                  {label}
                </option>
              ))}
            </>
          )}
        </ReactStrapInput>
        {rightContent}
      </div>
      {error?.errorMessage && error?.showError && (
        <p className="text-danger">
          <small>{error.errorMessage}</small>
        </p>
      )}
    </FormGroup>
  );
}

export default Input;
