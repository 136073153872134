import * as Yup from "yup";

export const AddUpdateSchema = (fields) =>
  Yup.object().shape(
    Object.fromEntries(
      fields.map((field) => [
        field.id,
        field.type === "number"
          ? Yup.number().required(`${field.label} is required`)
          : Yup.string().required(`${field.label} is required`),
      ])
    )
  );
