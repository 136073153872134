import React from "react";
import { Route, Switch } from "react-router-dom";
import BotDetail from "./components/BotDetail/index.js";
import BotList from "./components/BotList/index.js";

function ArbitrageBot() {
  return (
    <>
      <Switch>
        <Route
          path="/dashboard/arbitrage/:botId"
          render={() => <BotDetail />}
        />
        <Route component={BotList} />
      </Switch>
    </>
  );
}

export default ArbitrageBot;
