import Input from "components/Input";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Button,
} from "reactstrap";
import { AddUpdateSchema } from "./yup";

function AddUpdateModal({
  isOpen,
  toggle,
  selectedItem,
  setSelectedItem,
  config,
}) {
  const {
    errors,
    resetForm,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    values,
  } = useFormik({
    initialValues: Object.fromEntries(
      config.fields.map((field) => [field.id, field.default || ""])
    ),
    onSubmit: (values) => {
      config.callBackFn(values, clear, !!selectedItem);
    },
    validationSchema: AddUpdateSchema(config.fields),
  });
  const showError = Object.keys(touched).length !== 0;

  const clear = () => {
    setSelectedItem && setSelectedItem(false);
    resetForm();
  };

  useEffect(() => {
    if (selectedItem) {
      config.fields.forEach((field) =>
        setFieldValue(field.id, selectedItem?.[field.id])
      );
    }
  }, [selectedItem, setFieldValue, config.fields]);

  return (
    <Modal isOpen={!!isOpen} toggle={toggle}>
      <ModalHeader>{config.modalHeader}</ModalHeader>
      <ModalBody>
        <Form>
          {config.fields.map((field) => (
            <Input
              key={field.id}
              id={field.id}
              label={field.label}
              placeholder={field?.placeholder}
              value={values?.[field.id]}
              onChange={handleChange}
              type={field.type}
              options={field?.options?.map(({ label, value }) => ({
                label,
                value,
              }))}
              error={{ errorMessage: errors?.[field.id], showError }}
            />
          ))}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Add
        </Button>
        <Button
          onClick={() => {
            toggle();
            clear();
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default AddUpdateModal;
