import { useStoreState } from "easy-peasy";
import React from "react";
import { Route, Redirect } from "react-router-dom";

function PrivateRoute(props) {
  const isLoginUser = useStoreState((state) => state.isLoginUser);
  return isLoginUser ? (
    <Route {...props} />
  ) : (
    <Redirect from="/" to="/auth/login" />
  );
}

export default PrivateRoute;
