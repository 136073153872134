import React, { useEffect } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from "firebase/remote-config";
import { getAnalytics, setUserId } from "firebase/analytics";

import { useStoreActions, useStoreState } from "easy-peasy";

import AdminLayout from "./containers/Admin.js";
import AuthLayout from "./containers/Auth.js";
import PrivateRoute from "./containers/RouteLayouts/PrivateRoute.js";
import ReversePrivateRoute from "./containers/RouteLayouts/ReversePrivateRoute.js";
import Alert from "./containers/Alert/index.js";

function App() {
  const alertConfig = useStoreState((state) => state.alertConfig);
  const setUser = useStoreActions((actions) => actions.setUser);
  const setAuth = useStoreActions((actions) => actions.setAuth);
  const setDb = useStoreActions((actions) => actions.setDb);
  const setStorage = useStoreActions((actions) => actions.setStorage);
  const setPairList = useStoreActions((actions) => actions.setPairList);
  const setExchangeList = useStoreActions((actions) => actions.setExchangeList);
  const setAlertMessage = useStoreActions((actions) => actions.setAlertMessage);

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    const analytics = getAnalytics();
    setAuth(auth);
    setDb(db);

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const remoteConfig = getRemoteConfig();
        remoteConfig.settings = {
          minimumFetchIntervalMillis: 3600000,
        };
        setUser(user);
        setUserId(analytics, user.uid);
        fetchAndActivate(remoteConfig)
          .then(() => {
            const pairList = getValue(remoteConfig, "pair_list");
            setPairList(JSON.parse(pairList._value));
            const exchangeList = getValue(remoteConfig, "exchange_list");
            setExchangeList(JSON.parse(exchangeList._value));
          })
          .catch((err) => console.log(err));
      } else {
        setUser(undefined);
      }
    });
    return () => {
      unsubscribe();
    };
  }, [setUser, setAuth, setDb, setPairList, setStorage, setExchangeList]);

  return (
    <>
      <Alert
        text={alertConfig?.message}
        variant={alertConfig?.variant}
        toogle={() => setAlertMessage({})}
      />
      <BrowserRouter>
        <Switch>
          <PrivateRoute
            path="/dashboard"
            render={(props) => <AdminLayout {...props} />}
          />
          <ReversePrivateRoute
            path="/auth"
            render={(props) => <AuthLayout {...props} />}
          />
          <Redirect from="/dashboard/index" to="/dashboard/arbitrage" />
          <Redirect from="/" to="/dashboard/arbitrage" />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
