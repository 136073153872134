import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";

function ConfirmModal({ message }) {
  const confirmHandleSubmit = useStoreState(
    (state) => state.confirmHandleSubmit
  );
  const confirmHandleCancel = useStoreState(
    (state) => state.confirmHandleCancel
  );

  const setConfirm = useStoreActions((state) => state.setConfirm);
  const close = (isSubmit) => {
    setConfirm({ confirmMessage: "" });
    !isSubmit && confirmHandleCancel();
  };
  const handleSubmit = () => {
    confirmHandleSubmit();
    close(true);
  };

  return (
    <Modal isOpen={!!message} toggle={() => close(false)} centered>
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Yes
        </Button>
        <Button onClick={() => close(false)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmModal;
