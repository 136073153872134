import AddUpdateModal from "containers/Modals/AddUpdateModal";
import { useStoreState } from "easy-peasy";
import { useFireStore } from "../../../../utils/firebase/firestore";
import { makeKebabCase } from "../../../../utils/string";

function AddExchangeModal({
  isOpenAddModal,
  toggleAddModal,
  selectedExchange,
  setSelectedExchange,
  exchangeList,
  setExchangeList,
  callBackFn,
}) {
  const { addDoc } = useFireStore();
  const exchangeListOptions = useStoreState((state) => state.exchangeList);

  return (
    <AddUpdateModal
      isOpen={isOpenAddModal}
      toggle={toggleAddModal}
      selectedItem={selectedExchange}
      setSelectedItem={setSelectedExchange}
      config={{
        fields: [
          {
            id: "name",
            label: "Name",
            placeholder: "Joe's Binance Account",
            type: "text",
          },
          {
            id: "exchange",
            label: "Select Exchange Market",
            type: "select",
            options: exchangeListOptions,
            default: "Binance",
          },
          {
            id: "apiKey",
            label: "Api Key",
            placeholder: "(example): ZXpRprKRA4PbAwQy63sEdmYSguYgzFlJ",
            type: "text",
          },
          {
            id: "secretKey",
            label: "Secret Key",
            placeholder: "(example): Ju9mQ39rPZapDVD30UXfeCnij4P0S3T7",
            type: "text",
          },
        ],
        modalHeader: "Add Exchange",
        callBackFn: (
          { name, exchange = "binance", apiKey, secretKey },
          clear,
          isUpdate
        ) => {
          addDoc({
            path: `exchange-settings/${makeKebabCase(name)}`,
            data: {
              name: name,
              exchange: exchange,
              apiKey: apiKey,
              secretKey: secretKey,
              ...(!isUpdate ? { key: makeKebabCase(name) } : {}),
            },
          })
            .then(() => {
              if (isUpdate) {
                setExchangeList([
                  ...exchangeList.map((exc) =>
                    exc.key !== selectedExchange.key
                      ? exc
                      : {
                          key: selectedExchange.key,
                          name,
                          exchange,
                          apiKey,
                          secretKey,
                        }
                  ),
                ]);
              } else {
                setExchangeList([
                  ...exchangeList,
                  {
                    name,
                    exchange,
                    apiKey,
                    secretKey,
                    key: makeKebabCase(name),
                  },
                ]);
              }

              clear();
              callBackFn &&
                callBackFn(
                  isUpdate ? selectedExchange?.key : makeKebabCase(name)
                );
              toggleAddModal();
            })
            .catch((e) => console.log(e));
        },
      }}
    />
  );
}

export default AddExchangeModal;
