import Profile from "./views/examples/Profile.js";
import Register from "./views/register/index";
import Login from "./views/login/index.js";
import ArbitrageBot from "./views/ArbitrageBot/index.js";
import ExchangeSettings from "./views/ExchangeSettings/index.js";

const routes = [
  /* {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/dashboard",
  }, */
  {
    path: "/arbitrage",
    name: "Arbitrage Bot",
    icon: "ni ni-spaceship text-success",
    component: ArbitrageBot,
    layout: "/dashboard",
  },
  {
    path: "/exchange-settings",
    name: "Exchange Settings",
    icon: "ni ni-bullet-list-67 text-red",
    component: ExchangeSettings,
    layout: "/dashboard",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/user",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
];
export default routes;
