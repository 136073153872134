import React from "react";
import ExchangeList from "./components/ExchangeList";

function ExchangeSettings() {
  return (
    <>
      <ExchangeList />
    </>
  );
}

export default ExchangeSettings;
