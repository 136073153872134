import { createStore, action } from "easy-peasy";
import { signInWithEmailAndPassword } from "firebase/auth";

export const store = createStore({
  user: undefined,
  setUser: action((state, payload) => {
    state["user"] = payload;
    state["isLoginUser"] = !!payload;
  }),
  isLoginUser: true,
  auth: undefined,
  setAuth: action((state, payload) => {
    state["auth"] = payload;
  }),
  db: undefined,
  setDb: action((state, payload) => {
    state["db"] = payload;
  }),
  pairList: [],
  setPairList: action((state, payload) => {
    state["pairList"] = payload;
  }),
  exchangeList: [],
  setExchangeList: action((state, payload) => {
    state["exchangeList"] = payload;
  }),
  login: action((state, { email, password }) => {
    signInWithEmailAndPassword(state.auth, email, password).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(`${errorCode} : ${errorMessage} `);
    });
  }),
  logout: action((state) => {
    state.auth.signOut();
  }),
  confirmMessage: "",
  confirmHandleSubmit: () => {},
  confirmHandleCancel: () => {},
  setConfirm: action((state, payload) => {
    state["confirmMessage"] = payload?.confirmMessage;
    state["confirmHandleSubmit"] = payload?.handleSubmit || (() => {});
    state["confirmHandleCancel"] = payload?.handleCancel || (() => {});
  }),
  alertConfig: {},
  setAlertMessage: action((state, payload) => {
    state["alertConfig"]["variant"] = payload.variant || "success";
    state["alertConfig"]["message"] = payload.message;
  }),
});
