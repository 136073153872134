import ClickInput from "../../../../components/ClickInput/index.js";
import RightFloat from "../../../../components/RightFloat/index.js";
import TableCard from "../../../../components/TableCard/index.js";
import AddUpdateModal from "../../../../containers/Modals/AddUpdateModal.js";
import { useStoreActions } from "easy-peasy";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Container, Row, UncontrolledTooltip, Button } from "reactstrap";
import { BOT_STATUS } from "../../../../utils/enums";
import { useFireStore } from "../../../../utils/firebase/firestore";
import { displayFiat } from "../../../../utils/string";
import { makeKebabCase } from "../../../../utils/string";
import BotActivitiesModal from "../BotActivitiesModal/index.js";
import { SpreadShema } from "./yup";

function BotList() {
  const [isOpenAddBot, setOpenAddBot] = useState(false);
  const [selectedBot, setSelectedBot] = useState();
  const [botList, setBotList] = useState();
  const setConfirm = useStoreActions((state) => state.setConfirm);
  const setAlertMessage = useStoreActions((actions) => actions.setAlertMessage);

  const { getDocs, addDoc } = useFireStore();
  const toggleAddBot = () => setOpenAddBot(!isOpenAddBot);
  const getCoinLabels = (pairList = []) => {
    const coins = [];
    pairList?.forEach(({ pair, amount }) => {
      const [base = "", counter = ""] = pair?.split("_");
      !coins.map((e) => e.key).includes(base) &&
        coins.push({ key: base, amount });
      !coins.map((e) => e.key).includes(counter) &&
        coins.push({ key: counter });
    });
    return coins;
  };

  useEffect(() => {
    !botList &&
      getDocs({
        path: `exchange-arbitrage-bot`,
        setList: setBotList,
      });
  }, [getDocs, botList]);

  return (
    <>
      <AddUpdateModal
        isOpen={isOpenAddBot}
        toggle={toggleAddBot}
        selectedItem={selectedBot}
        setSelectedItem={setSelectedBot}
        config={{
          fields: [
            {
              id: "name",
              label: "Name",
              placeholder: "Joe's Arbitrage Bot",
              type: "text",
            },
          ],
          modalHeader: "Add Exchange",
          callBackFn: ({ name }, clear, isUpdate) => {
            addDoc({
              path: `exchange-arbitrage-bot/${makeKebabCase(name)}`,
              data: {
                name,
                key: makeKebabCase(name),
                status: BOT_STATUS.DRAFT.key,
                spread: 0.005,
                pairList: [{ pair: "", amount: "", status: false }],
              },
            })
              .then(() => {
                toggleAddBot();
                !isUpdate &&
                  setBotList([
                    ...botList,
                    {
                      name,
                      key: makeKebabCase(name),
                      status: BOT_STATUS.DRAFT.key,
                      spread: 0.005,
                      pairList: [{ pair: "", amount: "", status: false }],
                    },
                    setAlertMessage,
                  ]);
                clear();
              })
              .catch((e) => console.log(e));
          },
        }}
      />
      <BotActivitiesModal
        selectedBot={selectedBot}
        close={() => setSelectedBot(false)}
      />

      <Container className="h-100 pt-8" fluid>
        <Row className="h-100">
          <div className="col h-100">
            <TableCard
              cardHeaderLeftContent={
                <h3 className="mb-0">Exchange Arbitrage Bot List</h3>
              }
              cardHeaderRightContent={
                <Button
                  color="primary"
                  outline
                  size="sm"
                  onClick={toggleAddBot}
                >
                  +
                </Button>
              }
              headerLabels={[
                "Name",
                "Spread",
                <RightFloat>Balance</RightFloat>,
                "Status",
                "Coins",
                "Bot Aktivities",
              ]}
              values={botList?.map((bot) => {
                /* TODO: tüm botlar taşındığındığında bot.balance kısmı çıkarılacak */
                const balance =
                  bot?.balanceSnapshot?.lastBalance || bot?.balance;
                const balanceProfit = bot?.balanceSnapshot
                  ? bot?.balanceSnapshot?.lastBalance -
                    bot?.balanceSnapshot?.initialBalance -
                    bot?.balanceSnapshot?.deposit +
                    bot?.balanceSnapshot?.withdraw
                  : bot?.balanceProfit;
                const balanceProfitPercentage = bot?.balanceSnapshot
                  ? balanceProfit / bot?.balanceSnapshot?.initialBalance
                  : bot?.balanceProfitPercentage;

                return [
                  <Link
                    to={{
                      pathname: `/dashboard/arbitrage/${bot.key}`,
                      state: { data: bot },
                    }}
                  >
                    <div
                      className="font-weight-bold"
                      style={{ color: "#46A7E3" }}
                    >
                      {bot.name}
                    </div>
                  </Link>,
                  <ClickInput
                    id={`${bot.key}_checkinput`}
                    value={bot.spread}
                    onSubmit={({ newValue, oldValue }, cancelCallBackFn) => {
                      SpreadShema.validate({ spread: newValue })
                        .then(function (value) {
                          setConfirm({
                            confirmMessage: `Are you sure change ${bot.name}'s spread to ${value.spread} from ${oldValue}?`,
                            handleCancel: () => {
                              cancelCallBackFn && cancelCallBackFn();
                              document.activeElement.blur();
                            },
                            handleSubmit: () => {
                              addDoc({
                                path: `exchange-arbitrage-bot/${bot.key}`,
                                data: {
                                  ...bot,
                                  spread: value.spread,
                                },
                                setAlertMessage,
                              });
                              setBotList(
                                botList.map((element) => {
                                  if (bot.key === element.key) {
                                    return { ...bot, spread: value.spread };
                                  }
                                  return element;
                                })
                              );
                              document.activeElement.blur();
                            },
                          });
                        })
                        .catch((e) => {
                          e.errors.forEach((error) =>
                            setAlertMessage({ message: error })
                          );
                        });
                    }}
                  />,
                  balance ? (
                    <RightFloat>
                      {displayFiat(balance)}{" "}
                      {balanceProfitPercentage && (
                        <>
                          (
                          <span
                            id={`profitPercentage${bot.key}`}
                            style={{
                              color:
                                balanceProfitPercentage >= 0
                                  ? "#09AC19"
                                  : "#F30000",
                            }}
                          >
                            {(balanceProfitPercentage * 100).toFixed(2)}%
                          </span>
                          )
                        </>
                      )}
                      {balanceProfitPercentage && balanceProfit && (
                        <UncontrolledTooltip
                          placement="top"
                          target={`profitPercentage${bot.key}`}
                        >
                          {displayFiat(balanceProfit)}
                        </UncontrolledTooltip>
                      )}
                    </RightFloat>
                  ) : (
                    <RightFloat>-</RightFloat>
                  ),
                  bot.status ? (
                    <Badge
                      color=""
                      className="badge-dot mr-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (
                          bot.status === BOT_STATUS.STOP.key ||
                          bot.status === BOT_STATUS.START.key
                        ) {
                          const toStatus =
                            bot.status === BOT_STATUS.STOP.key
                              ? BOT_STATUS.START.key
                              : BOT_STATUS.STOP.key;
                          setConfirm({
                            confirmMessage: `Are you sure change ${bot.name}'s status to ${toStatus}?`,
                            handleSubmit: () => {
                              addDoc({
                                path: `exchange-arbitrage-bot/${bot.key}`,
                                data: {
                                  ...bot,
                                  status: toStatus,
                                },
                                setAlertMessage,
                              });
                              setBotList(
                                botList.map((element) => {
                                  if (bot.key === element.key) {
                                    return { ...bot, status: toStatus };
                                  }
                                  return element;
                                })
                              );
                              document.activeElement.blur();
                            },
                          });
                        }
                      }}
                    >
                      <i className={BOT_STATUS[bot.status].className} />
                      {bot.status}
                    </Badge>
                  ) : (
                    "-"
                  ),
                  <div className="avatar-group d-flex">
                    {getCoinLabels(bot?.pairList)?.map((coin, index) => (
                      <Fragment key={index}>
                        <a
                          className="avatar avatar-sm"
                          href="#pablo"
                          id={`${bot.key}_${coin.key}_${index}`}
                          onClick={(e) => e.preventDefault()}
                        >
                          <img
                            alt="..."
                            className="rounded-circle"
                            style={{ height: 32, width: 32 }}
                            src={`https://firebasestorage.googleapis.com/v0/b/auto-trade-d62b5.appspot.com/o/coin%2F${coin.key.toLowerCase()}.png?alt=media`}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`${bot.key}_${coin.key}_${index}`}
                        >
                          {`${coin.amount ? `${coin.amount} ` : ""}${coin.key}`}
                        </UncontrolledTooltip>
                      </Fragment>
                    ))}
                  </div>,
                  <div
                    style={{ color: "#46A7E3", cursor: "pointer" }}
                    onClick={() => setSelectedBot(bot)}
                  >
                    Show
                  </div>,
                ];
              })}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default BotList;
