import { useEffect, useState } from "react";
import {
  Button,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  UncontrolledTooltip,
  Container,
  Row,
} from "reactstrap";
import { makeKebabCase } from "../../../../utils/string";
import TableCard from "../../../../components/TableCard/index.js";
import { useFireStore } from "../../../../utils/firebase/firestore.js";
import AddExchangeModal from "../AddExchangeModal/index.js";
import { useStoreActions } from "easy-peasy";

function ExchangeList() {
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState(false);
  const [exchangeList, setExchangeList] = useState();
  const setConfirm = useStoreActions((state) => state.setConfirm);

  const toggleAddModal = () => setOpenAddModal(!isOpenAddModal);

  const { getDocs, deleteDoc } = useFireStore();

  useEffect(() => {
    !exchangeList &&
      getDocs({
        path: `exchange-settings`,
        setList: setExchangeList,
      });
  }, [getDocs, exchangeList]);

  return (
    <>
      <AddExchangeModal
        isOpenAddModal={isOpenAddModal}
        toggleAddModal={toggleAddModal}
        selectedExchange={selectedExchange}
        setSelectedExchange={setSelectedExchange}
        exchangeList={exchangeList}
        setExchangeList={setExchangeList}
      />

      <Container className="h-100 pt-8" fluid>
        <Row className="h-100">
          <div className="col h-100">
            <TableCard
              cardHeaderLeftContent={<h3 className="mb-0">Exchange List</h3>}
              cardHeaderRightContent={
                <Button
                  color="primary"
                  outline
                  size="sm"
                  onClick={toggleAddModal}
                >
                  +
                </Button>
              }
              headerLabels={["Name", "Exchange", "Api Key", "Secret Key", ""]}
              values={exchangeList?.map((exchange, index) => [
                <h5>{exchange.name}</h5>,
                <>
                  <UncontrolledTooltip
                    flip
                    target={`${exchange.exchange}_logo_${index}`}
                  >
                    {exchange.exchange}
                  </UncontrolledTooltip>
                  <a
                    className="avatar avatar-sm"
                    href="#pablo"
                    id={`${exchange.exchange}_logo_${index}`}
                    onClick={(e) => e.preventDefault()}
                    style={{ background: "none" }}
                  >
                    <img
                      alt={`${exchange.exchange}_logo`}
                      className="rounded-circle"
                      style={{ height: 32, width: 32 }}
                      src={`https://firebasestorage.googleapis.com/v0/b/auto-trade-d62b5.appspot.com/o/exchange%2Flogo%2F${exchange?.exchange?.toLowerCase()}.png?alt=media`}
                    />
                  </a>
                </>,
                <>
                  {exchange?.apiKey?.length > 25
                    ? `${exchange.apiKey.slice(0, 24)}...`
                    : exchange?.apiKey}
                </>,
                <>
                  {exchange?.secretKey?.length > 25
                    ? `${exchange.secretKey.slice(0, 24)}...`
                    : exchange?.secretKey}
                </>,
                <UncontrolledDropdown className="float-right">
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) => {
                        setSelectedExchange(exchange);
                        toggleAddModal();
                      }}
                    >
                      Update
                    </DropdownItem>
                    <DropdownItem
                      href="#pablo"
                      onClick={(e) =>
                        setConfirm({
                          confirmMessage: `Are you sure delete ${exchange.name}?`,
                          handleSubmit: () =>
                            deleteDoc({
                              path: `exchange-settings/${makeKebabCase(
                                exchange.name
                              )}`,
                              callBackFn: () => {
                                setExchangeList(
                                  exchangeList.filter(
                                    (item) => exchange.name !== item.name
                                  )
                                );
                              },
                            }),
                        })
                      }
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>,
              ])}
            />
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ExchangeList;
