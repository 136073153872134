import { useStoreActions, useStoreState } from "easy-peasy";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Media,
} from "reactstrap";
import routes from "routes";

function AdminNavbar({ brandText }) {
  const user = useStoreState((state) => state.user);
  const logout = useStoreActions((actions) => actions.logout);

  const createLinks = (routes) => {
    return routes
      .filter((route) => route.layout === "/dashboard")
      .map((prop, key) => {
        return (
          <NavItem key={key}>
            <NavLink href={prop.layout + prop.path} className="text-white mr-3">
              {prop.name}
            </NavLink>
          </NavItem>
        );
      });
  };

  return (
    <div
      className="bg-gradient-info navbar-top"
      style={{ height: 450, zIndex: "auto" }}
    >
      <Navbar className="navbar-top" id="navbar-main" style={{ height: 76 }}>
        <Container className="flex-start" fluid>
          <Nav className="align-items-center d-flex flex-row">
            {createLinks(routes)}
          </Nav>
          <Nav className="align-items-center d-flex flex-row" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  {user && (
                    <span className="avatar avatar-sm rounded-circle bg-transparent">
                      <img
                        alt="..."
                        height="24"
                        width="28"
                        style={{ height: 16, width: 16 }}
                        src={
                          require("../../assets/img/icons/common/user.png")
                            .default
                        }
                      />
                    </span>
                  )}
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold nav-link text-white">
                      {user?.displayName || user?.email}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default AdminNavbar;
