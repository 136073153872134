import { useEffect } from "react";

function useOutSideClick(ref, isOpenListener, callbackFn) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callbackFn();
      }
    }

    isOpenListener &&
      document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [ref, isOpenListener]);
}

export default useOutSideClick;
