import React, { useEffect, useMemo, useState } from "react";
import TableCard from "../../../../components/TableCard/index.js";
import { Modal } from "reactstrap";
import { useFireStore } from "../../../../utils/firebase/firestore";
import { getDateDisplayWithSecond } from "utils/date";
import { displayFiat } from "../../../../utils/string";
import RightFloat from "../../../../components/RightFloat/index.js";
import ClickInput from "../../../../components/ClickInput/index.js";
import { useStoreActions } from "easy-peasy";
import { WithdrawDepositShema } from "./yup";
import Input from "../../../../components/Input/index.js";

function BotActivitiesModal({ selectedBot, close, config }) {
  const [activities, setActivities] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const { getDocs, addDoc } = useFireStore();
  const setConfirm = useStoreActions((state) => state.setConfirm);
  const setAlertMessage = useStoreActions((actions) => actions.setAlertMessage);

  const calcProfit = (activitie) => {
    return (
      activitie?.lastBalance +
      activitie?.withdraw -
      activitie?.initialBalance -
      activitie?.deposit
    );
  };

  const [totalProfit, totalProfitRate] = useMemo(() => {
    let sum = 0,
      totalDeposit = 0,
      totalWithdraw = 0,
      initialBalanceOfMonth = undefined;
    activities.forEach((activitie) => {
      if (initialBalanceOfMonth === undefined) {
        initialBalanceOfMonth = activitie?.initialBalance;
      }
      sum += calcProfit(activitie);
      totalDeposit += activitie?.deposit || 0;
      totalWithdraw += activitie?.withdraw || 0;
    });

    const totalProfitRate =
      (sum / (initialBalanceOfMonth + totalDeposit - totalWithdraw)) * 100;
    return [sum, totalProfitRate];
  }, [activities]);

  useEffect(() => {
    if (selectedBot) {
      const now = new Date();
      const year = now?.getFullYear();
      const month = now?.getMonth() + 1;
      setSelectedMonth(`${year}-${month > 9 ? month : `${month}`}`);
    }
  }, [selectedBot]);

  useEffect(() => {
    if (selectedMonth) {
      getDocs({
        path: `exchange-arbitrage-bot/${selectedBot.key}/bot-activities/${selectedMonth}/daily-records`,
        setList: setActivities,
      });
    } else {
      setActivities([]);
    }
    // eslint-disable-next-line
  }, [selectedMonth, selectedBot]);

  return (
    <Modal isOpen={!!selectedBot} toggle={close} size="xl" centered>
      <TableCard
        headStyle={{ backgroundColor: "rgba(246, 249, 252, 0.3)" }}
        filterContent={
          <div className="d-flex justify-content-between align-items-center pr-3 pl-3 w-100">
            <Input
              id="month"
              value={selectedMonth}
              onChange={(value) =>
                value?.target?.value && setSelectedMonth(value?.target?.value)
              }
              options={[
                { label: "2024-12", value: "2024-12" },
                { label: "2024-11", value: "2024-11" },
                { label: "2024-10", value: "2024-10" },
                { label: "2024-09", value: "2024-9" },
                { label: "2024-08", value: "2024-8" },
                { label: "2024-07", value: "2024-7" },
                { label: "2024-06", value: "2024-6" },
                { label: "2024-05", value: "2024-5" },
                { label: "2024-04", value: "2024-4" },
                { label: "2024-03", value: "2024-3" },
                { label: "2024-02", value: "2024-2" },
                { label: "2024-01", value: "2024-1" },
                { label: "2023-12", value: "2023-12" },
                { label: "2023-11", value: "2023-11" },
                { label: "2023-10", value: "2023-10" },
                { label: "2023-09", value: "2023-9" },
                { label: "2023-08", value: "2023-8" },
                { label: "2023-07", value: "2023-7" },
                { label: "2023-06", value: "2023-6" },
                { label: "2023-05", value: "2023-5" },
                { label: "2023-04", value: "2023-4" },
                { label: "2023-03", value: "2023-3" },
                { label: "2023-02", value: "2023-2" },
                { label: "2023-01", value: "2023-1" },
                { label: "2022-12", value: "2022-12" },
                { label: "2022-11", value: "2022-11" },
                { label: "2022-10", value: "2022-10" },
                { label: "2022-09", value: "2022-9" },
                { label: "2022-08", value: "2022-8" },
                { label: "2022-07", value: "2022-7" },
                { label: "2022-06", value: "2022-6" },
                { label: "2022-05", value: "2022-5" },
                { label: "2022-04", value: "2022-4" },
                { label: "2022-03", value: "2022-3" },
                { label: "2022-02", value: "2022-2" },
                { label: "2022-01", value: "2022-1" },
              ]}
              type="select"
              bsSize="sm"
              inputStyle={{ width: 120 }}
              containerStyle={{ margin: 0 }}
            />
            <div style={{ fontSize: 12 }}>
              Total Profit:{" "}
              <b>
                {displayFiat(totalProfit)} ({totalProfitRate?.toFixed(2)}%)
              </b>
            </div>
          </div>
        }
        cardHeaderLeftContent={
          <div style={{ fontWeight: 600, fontSize: 16 }}>
            {selectedBot?.name}'s Activities
          </div>
        }
        cardHeaderRightContent={
          <i
            className="fas fa-times"
            style={{ fontSize: 18, cursor: "pointer", fontWeight: 600 }}
            onClick={close}
          />
        }
        headerLabels={[
          "Date",
          <RightFloat>Profit</RightFloat>,
          <RightFloat>Balance</RightFloat>,
          <RightFloat>Total Balance</RightFloat>,
          <RightFloat>Deposit</RightFloat>,
          <RightFloat>Withdraw</RightFloat>,
        ]}
        values={activities
          ?.sort(function (a, b) {
            return b?.createAt?.seconds - a?.createAt?.seconds;
          })
          ?.map((activitie) => [
            getDateDisplayWithSecond(activitie?.createAt.seconds),
            <RightFloat>
              {displayFiat(calcProfit(activitie))} (
              <span
                style={{
                  color: calcProfit(activitie) >= 0 ? "#09AC19" : "#F30000",
                }}
              >
                {(
                  (calcProfit(activitie) / activitie?.initialBalance) *
                  100
                ).toFixed(2)}
                %
              </span>
              )
            </RightFloat>,
            <RightFloat>{displayFiat(activitie?.lastBalance)}</RightFloat>,
            <RightFloat>{displayFiat(activitie?.lastTotalBalance)}</RightFloat>,
            <RightFloat className="d-flex">
              <ClickInput
                id={`deposit_${activitie?.createAt.seconds}`}
                value={activitie?.deposit}
                type="text"
                inputMode="decimal"
                inputStyles={{
                  textAlign: "right",
                }}
                rightContent={
                  <div
                    className="d-flex align-items-center ml-1"
                    style={{ fontSize: 12 }}
                  >
                    $
                  </div>
                }
                onSubmit={({ newValue, oldValue }, cancelCallBackFn) => {
                  WithdrawDepositShema.validate({ value: newValue })
                    .then(function ({ value }) {
                      console.log("yup value", value, oldValue);
                      setConfirm({
                        confirmMessage: `Are you sure change the deposit from ${oldValue}$ to ${newValue}$?`,
                        handleCancel: () => {
                          cancelCallBackFn && cancelCallBackFn();
                          document.activeElement.blur();
                        },
                        handleSubmit: () => {
                          addDoc({
                            path: `exchange-arbitrage-bot/${
                              selectedBot.key
                            }/bot-activities/${new Date(
                              activitie?.createAt.seconds * 1000
                            ).getFullYear()}-${
                              new Date(
                                activitie?.createAt.seconds * 1000
                              ).getMonth() + 1
                            }/daily-records/DAY-${new Date(
                              activitie?.createAt.seconds * 1000
                            ).getDate()}`,
                            data: {
                              deposit: value,
                            },
                            setAlertMessage,
                          });
                        },
                      });
                    })
                    .catch((e) => {
                      e.errors.forEach((error) =>
                        setAlertMessage({ message: error })
                      );
                    });
                }}
              />
            </RightFloat>,
            <RightFloat>
              <ClickInput
                id={`withdraw_${activitie?.createAt.seconds}`}
                value={activitie?.withdraw}
                inputStyles={{ textAlign: "right" }}
                rightContent={
                  <div
                    className="d-flex align-items-center ml-1"
                    style={{ fontSize: 12 }}
                  >
                    $
                  </div>
                }
                onSubmit={({ newValue, oldValue }, cancelCallBackFn) => {
                  WithdrawDepositShema.validate({ value: newValue })
                    .then(function ({ value }) {
                      console.log("yup value", value, oldValue);
                      setConfirm({
                        confirmMessage: `Are you sure change the withdraw from ${oldValue}$ to ${newValue}$?`,
                        handleCancel: () => {
                          cancelCallBackFn && cancelCallBackFn();
                          document.activeElement.blur();
                        },
                        handleSubmit: () => {
                          addDoc({
                            path: `exchange-arbitrage-bot/${
                              selectedBot.key
                            }/bot-activities/${new Date(
                              activitie?.createAt.seconds * 1000
                            ).getFullYear()}-${
                              new Date(
                                activitie?.createAt.seconds * 1000
                              ).getMonth() + 1
                            }/daily-records/DAY-${new Date(
                              activitie?.createAt.seconds * 1000
                            ).getDate()}`,
                            data: {
                              withdraw: value,
                            },
                            setAlertMessage,
                          });
                        },
                      });
                    })
                    .catch((e) => {
                      e.errors.forEach((error) =>
                        setAlertMessage({ message: error })
                      );
                    });
                }}
              />
            </RightFloat>,
          ])}
      />
    </Modal>
  );
}

export default BotActivitiesModal;
