import React from "react";
import ReactDOM from "react-dom";
import { StoreProvider } from "easy-peasy";

console.log(process.env);

import "./utils/firebase/index.js";
import { store } from "./utils/easyPeasy";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/argon-dashboard-react.css";
import App from "./App.js";


ReactDOM.render(
  <StoreProvider store={store}>
    <App />
  </StoreProvider>,
  document.getElementById("root")
);
