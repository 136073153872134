import { useStoreState } from "easy-peasy";
import {
  collection,
  doc,
  runTransaction,
  query,
  getDocs,
  getDoc,
} from "firebase/firestore";

export async function fsAddDoc(db, { path, data, setAlertMessage }) {
  const exchangeRef = doc(db, path);
  try {
    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(exchangeRef);
      if (!sfDoc.exists()) {
        transaction.set(exchangeRef, data);
      }

      transaction.update(exchangeRef, data);
    });
    setAlertMessage && setAlertMessage({ message: "Successfully committed!" });
    console.log("Transaction successfully committed!");
  } catch (e) {
    console.log("Transaction failed: ", e);
    setAlertMessage &&
      setAlertMessage({ message: "An error has occurred!", variant: "danger" });
  }
}

export async function fsGetDocs(db, { path, setList, setRefList }) {
  const result = [];
  const q = query(collection(db, path));
  const docSnap = await getDocs(q);

  docSnap.forEach((doc) => {
    result.push(doc);
  });
  setList &&
    setList(
      result.map((doc) => {
        return { ...doc.data(), id: doc.id };
      })
    );
  setRefList && setRefList(result);
  return result;
}

export async function fsGetDoc(db, { path, ref, setData }) {
  const docRef = ref || doc(db, path);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    setData(data);
    return data;
  } else {
    console.log("No such document!");
  }
}

export async function fsDeleteDoc(db, { path, callBackFn = () => {} }) {
  const exchangeRef = doc(db, path);

  try {
    await runTransaction(db, async (transaction) => {
      const sfDoc = await transaction.get(exchangeRef);
      if (!sfDoc.exists()) {
        console.log("Item not exist");
        return;
      }
      transaction.delete(exchangeRef);
      callBackFn();
      console.log("Transaction successfully deleleted!");
    });
  } catch (e) {
    console.log("Transaction failed: ", e);
  }
}

export function useFireStore() {
  const db = useStoreState((state) => state.db);
  const user = useStoreState((state) => state.user);
  const addDoc = ({ path, ...rest }) =>
    user?.uid &&
    fsAddDoc(db, { path: `auto-trade-bot/${user?.uid}/${path}`, ...rest });
  const getDocs = ({ path, ...rest }) =>
    user?.uid &&
    fsGetDocs(db, { path: `auto-trade-bot/${user?.uid}/${path}`, ...rest });
  const getDoc = ({ path, ...rest }) =>
    user?.uid &&
    fsGetDoc(db, { path: `auto-trade-bot/${user?.uid}/${path}`, ...rest });
  const deleteDoc = ({ path, ...rest }) =>
    user?.uid &&
    fsDeleteDoc(db, { path: `auto-trade-bot/${user?.uid}/${path}`, ...rest });

  return { addDoc, getDocs, getDoc, deleteDoc };
}
