import { Card, CardHeader, Table } from "reactstrap";

function TableCard({
  cardHeaderLeftContent,
  cardHeaderRightContent,
  headerLabels,
  values = [],
  filterContent,
  headStyle,
}) {
  return (
    <Card className="shadow h-100" style={{ minHeight: 400 }}>
      <CardHeader className="border-0 p-3" style={headStyle}>
        <div className="d-flex justify-content-between align-items-center">
          {cardHeaderLeftContent}
          {cardHeaderRightContent}
        </div>
      </CardHeader>
      {filterContent && (
        <div
          style={{
            height: 50,
            borderTop: "1px solid #e9ecef",
            display: "flex",
          }}
        >
          {filterContent}
        </div>
      )}
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light" style={{ backgroundColor: "#F6F9FC" }}>
          <tr>
            {headerLabels.map((label, index) => (
              <th
                className="bg-transparent"
                scope="col"
                key={index}
                style={{ color: "#48525C" }}
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values.map((valueMap, index) => (
            <tr
              key={index}
              style={{
                ...(index % 2 === 1 && { background: "rgb(246,249,252,0.3)" }),
              }}
            >
              {valueMap.map((value, index) => (
                <td key={index}>{value}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </Card>
  );
}

export default TableCard;
