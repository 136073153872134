import { useStoreState } from "easy-peasy";
import React from "react";
import { Route, Redirect } from "react-router-dom";

function ReversePrivateRoute(props) {
  const isLoginUser = useStoreState((state) => state.isLoginUser);
  return !isLoginUser ? (
    <Route {...props} />
  ) : (
    <Redirect from="/" to="/dashboard" />
  );
}

export default ReversePrivateRoute;
