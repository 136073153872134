import Input from "../../../../components/Input/index.js";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Form,
  Row,
  Button,
  ButtonGroup,
} from "reactstrap";
import { useFireStore } from "../../../../utils/firebase/firestore";
import { makeKebabCase } from "../../../../utils/string.js";
import PairForm from "./PairForm";
import { BOT_STATUS } from "../../../../utils/enums.js";
import { ArbitrageBotConfigSchema } from "./yup";
import { useStoreActions, useStoreState } from "easy-peasy";
import AddExchangeModal from "../../../../views/ExchangeSettings/components/AddExchangeModal/index.js";
import { AUTO_BUY } from "../../../../utils/enums.js";

function BotDetail() {
  const [botConfig, setBotConfig] = useState();
  const [exchangeList, setExchangeList] = useState();
  const [isOpenAddModal, setOpenAddModal] = useState(false);
  const [autoBuy, setAutoBuy] = useState({});
  const toggleAddModal = (key = false) => setOpenAddModal(key);

  const setAlertMessage = useStoreActions((actions) => actions.setAlertMessage);
  const setConfirm = useStoreActions((state) => state.setConfirm);
  const pairList = useStoreState((state) => state.pairList);
  const params = useParams();
  const { addDoc, deleteDoc, getDoc, getDocs } = useFireStore();
  const location = useLocation();
  const history = useHistory();
  const { errors, touched, setFieldValue, handleSubmit, handleChange, values } =
    useFormik({
      initialValues: {
        name: "",
        status: BOT_STATUS.DRAFT.key,
        spread: "",
        sourceMarket: undefined,
        targetMarket: undefined,
        pairList: [],
      },
      onSubmit: (values) => {
        const autoBuyInfoList = [
          !!autoBuy?.source?.show &&
            !!autoBuy?.source?.status && {
              currency: autoBuy?.source?.currency,
              exchange: autoBuy?.source?.exchange,
            },
          !!autoBuy?.target?.status &&
            !!autoBuy?.target?.currency && {
              currency: autoBuy?.target?.currency,
              exchange: autoBuy?.target?.exchange,
            },
        ];

        addDoc({
          path: `exchange-arbitrage-bot/${botConfig.key}`,
          data: {
            ...botConfig,
            ...values,
            spreadTolerance: values?.spread > 0.003 ? 0.001 : 0.0005,
            autoBuy: autoBuyInfoList,
          },
          setAlertMessage,
        });
        setBotConfig({ ...botConfig, ...values });
      },
      validationSchema: ArbitrageBotConfigSchema,
    });
  const showError = Object.keys(touched).length !== 0;
  const removePair = (index) => {
    setFieldValue(
      "pairList",
      values?.pairList?.filter((_, i) => index !== i)
    );
  };

  useEffect(() => {
    if (botConfig) {
      botConfig.name && setFieldValue("name", botConfig.name);
      botConfig.status && setFieldValue("status", botConfig.status);
      botConfig.spread && setFieldValue("spread", botConfig.spread);
      botConfig.sourceMarket &&
        setFieldValue("sourceMarket", botConfig.sourceMarket);
      botConfig.targetMarket &&
        setFieldValue("targetMarket", botConfig.targetMarket);
      botConfig.pairList && setFieldValue("pairList", botConfig.pairList);
      botConfig.autoBuy &&
        setAutoBuy({
          source: {
            status: !!botConfig.autoBuy?.[0],
          },
          target: {
            status: !!botConfig.autoBuy?.[1],
          },
        });
    }
  }, [botConfig, setFieldValue]);

  useEffect(() => {
    !exchangeList &&
      getDocs({
        path: `exchange-settings`,
        setList: setExchangeList,
      });
  }, [getDocs, exchangeList]);

  useEffect(() => {
    if (botConfig) return;
    /* çok fazla request olursa bit önceki listeden alınacak */ else if (
      params?.botId
    ) {
      getDoc({
        path: `exchange-arbitrage-bot/${makeKebabCase(params?.botId)}`,
        setData: setBotConfig,
      });
    }
  }, [location, params, getDoc, botConfig]);

  useEffect(() => {
    const sourceExchange = exchangeList?.filter(
      (exchange) => exchange.key === values.sourceMarket
    )?.[0]?.exchange;
    const targetExchange = exchangeList?.filter(
      (exchange) => exchange.key === values.targetMarket
    )?.[0]?.exchange;

    const data = {
      source: {
        ...autoBuy?.source,
        show: !!AUTO_BUY[sourceExchange],
        exchange: sourceExchange,
        currency: AUTO_BUY?.[sourceExchange]?.currency,
      },
      target: {
        ...autoBuy?.target,
        show: !!AUTO_BUY[targetExchange],
        exchange: targetExchange,
        currency: AUTO_BUY?.[targetExchange]?.currency,
      },
    };
    if (
      data.source.show !== autoBuy?.source?.show ||
      data.target.show !== autoBuy?.target?.show
    ) {
      setAutoBuy(data);
    }
    // eslint-disable-next-line
  }, [values.sourceMarket, values.targetMarket, exchangeList, autoBuy]);
  return (
    <Container className="h-100 pt-8" fluid>
      <AddExchangeModal
        isOpenAddModal={!!isOpenAddModal}
        toggleAddModal={toggleAddModal}
        exchangeList={exchangeList}
        setExchangeList={setExchangeList}
        callBackFn={(key) => {
          setFieldValue(isOpenAddModal, key);
        }}
      />
      <Row className="h-100 col h-100">
        {botConfig && (
          <Card className="shadow h-100 w-100" style={{ minHeight: 400 }}>
            <CardHeader className="border-0">
              <div className="d-flex justify-content-between">
                <h3 className="mb-0">{botConfig.name}</h3>
                <div>
                  <p className="mb-0">{botConfig.ip}</p>
                  {!botConfig.ip && (
                    <Button className="ml-2" color="primary">
                      Get IP
                    </Button>
                  )}
                </div>
              </div>
            </CardHeader>
            <Form className="pl-4 pr-4" onSubmit={handleSubmit}>
              <Input
                id="name"
                label="Name"
                value={values.name}
                onChange={handleChange}
                error={{ errorMessage: errors?.name, showError }}
                rightContent={
                  <ButtonGroup>
                    <Button
                      className="ml-2"
                      color={
                        values.status === BOT_STATUS.START.key
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => {
                        setFieldValue(`status`, BOT_STATUS.START.key);
                        handleSubmit();
                      }}
                      disabled={!botConfig.ip}
                    >
                      {BOT_STATUS.START.key}
                    </Button>
                    <Button
                      color={
                        values.status !== BOT_STATUS.START.key
                          ? "primary"
                          : "secondary"
                      }
                      onClick={() => {
                        setFieldValue(
                          `status`,
                          botConfig.ip
                            ? BOT_STATUS.STOP.key
                            : BOT_STATUS.DRAFT.key
                        );
                        handleSubmit();
                      }}
                    >
                      {botConfig.ip
                        ? BOT_STATUS.STOP.key
                        : BOT_STATUS.DRAFT.key}
                    </Button>
                  </ButtonGroup>
                }
              />
              <Input
                id="spread"
                label="Spread"
                value={values.spread}
                onChange={handleChange}
                type="number"
                error={{ errorMessage: errors?.spread, showError }}
                rightContent={
                  <span className="ml-3" style={{ lineHeight: 1 }}>
                    <small>
                      Spread Tolerance:{" "}
                      {values?.spread > 0.003 ? 0.001 : 0.0005}
                    </small>
                  </span>
                }
              />

              <Input
                id="sourceMarket"
                label="Source Market"
                value={values.sourceMarket}
                onChange={handleChange}
                options={exchangeList?.map((exchange) => ({
                  label: exchange.name,
                  value: exchange.key,
                }))}
                type="select"
                rightContent={
                  <>
                    {autoBuy?.source?.show && (
                      <Input
                        id="sorce_checkbox"
                        type="checkbox"
                        label={`Auto buy ${autoBuy?.source?.currency} with USDT`}
                        checked={!!autoBuy?.source?.status}
                        onChange={(e) =>
                          setAutoBuy({
                            ...autoBuy,
                            source: {
                              ...autoBuy.source,
                              status: e.target.checked,
                            },
                          })
                        }
                        className="ml-3"
                        inline
                      />
                    )}
                    <Button
                      className="ml-2"
                      color="primary"
                      onClick={() => toggleAddModal("sourceMarket")}
                      outline
                    >
                      +
                    </Button>
                  </>
                }
                error={{
                  errorMessage: errors?.sourceMarket,
                  showError,
                }}
              />
              <Input
                id="targetMarket"
                label="Target Market"
                value={values.targetMarket}
                onChange={handleChange}
                options={exchangeList?.map((exchange) => ({
                  label: exchange.name,
                  value: exchange.key,
                }))}
                type="select"
                rightContent={
                  <>
                    {autoBuy?.target?.show && (
                      <Input
                        id="target_checkbox"
                        type="checkbox"
                        label={`Auto buy ${autoBuy?.target?.currency} with USDT`}
                        checked={!!autoBuy?.target?.status}
                        onChange={(e) => {
                          setAutoBuy({
                            ...autoBuy,
                            target: {
                              ...autoBuy.target,
                              status: e.target.checked,
                            },
                          });
                        }}
                        className="ml-3"
                        inline
                      />
                    )}
                    <Button
                      className="ml-2"
                      color="primary"
                      onClick={() => toggleAddModal("targetMarket")}
                      outline
                    >
                      +
                    </Button>
                  </>
                }
                error={{
                  errorMessage: errors?.targetMarket,
                  showError,
                }}
              />

              {values?.pairList?.map((_, index) => (
                <PairForm
                  key={index}
                  index={index}
                  pairList={pairList}
                  pair={values.pairList[index].pair}
                  amount={values.pairList[index].amount}
                  status={values.pairList[index].status}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors?.pairList?.[index]}
                  showError={showError}
                  removePair={removePair}
                />
              ))}

              <div className="d-flex justify-content-between">
                <Button
                  className="mb-4"
                  color="primary"
                  outline
                  onClick={() => {
                    const newElement = { pair: "", amount: "", status: false };
                    setFieldValue(
                      "pairList",
                      values?.pairList
                        ? [...values?.pairList, newElement]
                        : [newElement]
                    );
                  }}
                >
                  Add New Pair
                </Button>
                <div>
                  <Button
                    href="#"
                    className="mb-4"
                    color="danger"
                    outline
                    onClick={() =>
                      setConfirm({
                        confirmMessage: `Are you sure delete ${botConfig?.name}?`,
                        handleSubmit: () =>
                          deleteDoc({
                            path: `exchange-arbitrage-bot/${botConfig.key}`,
                            callBackFn: () => {
                              history.replace("/dashboard/arbitrage");
                            },
                          }),
                      })
                    }
                  >
                    Delete
                  </Button>
                  <Button
                    href="#"
                    className="mb-4"
                    color="primary"
                    outline
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </div>
              </div>
            </Form>
          </Card>
        )}
      </Row>
    </Container>
  );
}

export default BotDetail;
