import React, { useEffect, useRef, useState } from "react";
import Input from "../../components/Input";
import useOutSideClick from "../../utils/hooks/useOutSideClick";

function ClickInput({ id, value, onSubmit, inputStyles = {}, ...rest }) {
  const [isInputState, setInputState] = useState(false);
  const [inputValue, setValue] = useState(value);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useOutSideClick(containerRef, isInputState, () => {
    isInputState && setInputState(false);
  });

  useEffect(() => {
    setValue(value);
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (+inputValue !== +value) {
      onSubmit &&
        onSubmit({ newValue: inputValue, oldValue: value }, () => {
          setValue(value);
        });
    }
    if (!isInputState) {
      const input = document.querySelector(`#${id}`);
      input.blur();
    }

    // eslint-disable-next-line
  }, [isInputState]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (inputValue !== value) {
        onSubmit &&
          onSubmit({ newValue: inputValue, oldValue: value }, () => {
            console.log(value);
            setValue(value);
          });
        setInputState(false);
      }
    }
  };

  return (
    <div
      ref={containerRef}
      onClick={() => setInputState(true)}
      style={{ width: 80 }}
    >
      <Input
        id={id}
        inputref={inputRef}
        value={inputValue}
        inputContainerstyle={{ height: 24 }}
        containerStyle={{ marginBottom: 0 }}
        inputStyle={{
          height: 24,
          padding: 4,
          borderRadius: 2,
          fontSize: 12,
          cursor: !isInputState ? "pointer" : "unset",
          color: "#525F7F",
          fontWeight: !isInputState ? 400 : 600,
          borderColor: !isInputState ? "#E9ECEF" : "#000000",
          ...inputStyles,
        }}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
        {...rest}
      />
    </div>
  );
}

export default ClickInput;
