export function getEnv() {
  const env = process.env.REACT_APP_NODE_ENV;
  if (env === "PROD") {
    return {
      REACT_APP_FIREBASE_API_KEY: "AIzaSyCCpWM7uyxPdq6NR9nqgzuVy3PdZazTJyU",
      REACT_APP_FIREBASE_AUTH_DOMAIN: "auto-trade-d62b5.firebaseapp.com",
      REACT_APP_FIREBASE_PROJECT_ID: "auto-trade-d62b5",
      REACT_APP_FIREBASE_STORAGE_BUCKET: "auto-trade-d62b5.appspot.com",
      REACT_APP_FIREBASE_MESSAGING_SENDER_ID: 491161627333,
      REACT_APP_FIREBASE_APP_ID: "1:491161627333:web:e7121089b5fef96838deac",
      REACT_APP_FIREBASE_MEASUREMENT_ID: "G-FPSGS05MCZ",
    };
  } else {
    return {
      REACT_APP_FIREBASE_API_KEY: "AIzaSyAEOZKknx7LWCjpPZlbNIYr2t90UFlbrYs",
      REACT_APP_FIREBASE_AUTH_DOMAIN: "arbiny-test.firebaseapp.com",
      REACT_APP_FIREBASE_PROJECT_ID: "arbiny-test",
      REACT_APP_FIREBASE_STORAGE_BUCKET: "arbiny-test.appspot.com",
      REACT_APP_FIREBASE_MESSAGING_SENDER_ID: 875363710374,
      REACT_APP_FIREBASE_APP_ID: "1:875363710374:web:4eea0d730c7b0b4cda4b67",
      REACT_APP_FIREBASE_MEASUREMENT_ID: "G-M0WLHX5LW1",
      REACT_APP_NODE_ENV: "TEST",
    };
  }
}
